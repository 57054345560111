import { selector, waitForAll } from "recoil";
import { courseInformationSheetsState } from "../../atoms/hsg/courseInformationSheets";
// import { userStudyPlanState } from "../../atoms/shsg_studyplan/userStudyPlan";
import { allocatedCoursesState } from "../../atoms/hsg/allocatedCourses";
import { relevantCisIdsQuery } from "../hsg/relevantCisIds";
import { avgCourseRatingsState } from "../../atoms/shsg_ratings/avgCourseRatings";
import { cisTermAndPhaseInformationsState } from "../../atoms/hsg/cisTermAndPhaseInformations";
import { compulsoryStudiesRemapperSelector } from "./compulsoryStudiesRemapper";

export const cleanedCoursesQuery = selector({
  key: "cleanedCourses",
  get: async ({ get }) => {
    const [
      allocatedCourses,
      courseInformationSheets,
      enrolledSemesters,
      avgCourseRatings,
      cisTermAndPhaseInformations,
      compulsoryStudiesRemapper,
    ] = get(
      waitForAll([
        allocatedCoursesState,
        courseInformationSheetsState,
        relevantCisIdsQuery,
        avgCourseRatingsState,
        cisTermAndPhaseInformationsState,
        compulsoryStudiesRemapperSelector,
      ])
    );

    const AutumRegex = /Autumn Semester/;
    // const SpringRegex = /Spring Semester/;
    function createCourseLink(course, semesterAbbreviation) {
      return `https://tools.unisg.ch/handlers/Public/CourseInformationSheet.ashx/semester/${semesterAbbreviation}/eventnumber/${course.courseNumber}.pdf`;
    }

    const data = {};
    enrolledSemesters.forEach((enrolledSemester) => {
      const cisTermId = enrolledSemester.cisTermId;
      const termId = enrolledSemester.termId;

      const semesterDescription = cisTermAndPhaseInformations.find(
        (semester) => cisTermId === semester.id
      ).description;

      const semesterAbbreviation = AutumRegex.test(semesterDescription)
        ? `HS${semesterDescription.slice(-2)}`
        : `FS${semesterDescription.slice(-2)}`;

      const parentCourses = courseInformationSheets.find(
        (semester) => semester.id === cisTermId
      ).data;

      const parentCoursesWithLink = parentCourses.map((parentCourse) => {
        return {
          ...parentCourse,
          semesterAbbreviation: semesterAbbreviation,
        };
      });

      const tempData = parentCoursesWithLink
        .map((parentCourse) => {
          return parentCourse.courses.map((childCourse) => {
            let courseLink;
            try {
              if (childCourse && semesterAbbreviation) {
                courseLink = createCourseLink(childCourse, semesterAbbreviation);
              } else {
                console.warn('childCourse or semesterAbbreviation is undefined: ', { childCourse, semesterAbbreviation });
              }
            } catch (error) {
              console.error('Error creating course link in cleanedCourses.jsx', error);
            }


            const finalCourse = {
              ...parentCourse,
              ...childCourse,
              courseLink: courseLink || '#', // fallback value in case try above fails
              selected: false,
              allocated: false,
              overlapping: false,
              ...(compulsoryStudiesRemapper.includes(
                parentCourse.classification
              ) && { classification: "Compulsory Subjects" }),
            };
            avgCourseRatings.forEach((rating) => {
              if (rating._id === finalCourse.courseNumber) {
                finalCourse.avgRating = rating.avgRating;
              }
            });

            // Tagging allocated courses
            if (
              Object.keys(allocatedCourses).includes(termId) &&
              allocatedCourses[termId] &&
              Array.isArray(allocatedCourses[termId]) &&
              allocatedCourses[termId]
                .filter(course => course && course.eventCourseNumber) // added course.eventCourseNumber check
                .map((course) => course.eventCourseNumber) // added course.eventCourseNumber check
                .includes(finalCourse.courseNumber)
            ) {
              finalCourse.allocated = true;
              finalCourse.status = "allocated";
            }

            finalCourse.calendarEntry =
              finalCourse.calendarEntry.filter((entry) => {
                return (
                  entry.courseNumber ===
                  finalCourse.courseNumber &&
                  entry.eventDate !== null
                );
              });

            return finalCourse;
          });
        })
        .flat();

      data[cisTermId] = tempData;
    });


  console.debug("Available cisTermIds in data:", Object.keys(data));

    const newData = {
      ...data,
      // "HS 23 - Placeholder": data[
      // "d33babff-17c7-4997-b35b-a5348a2cb7bd"
      // ].map((course) => {
      // return {
      //     ...course,
      //     selected: false,
      //     allocated: false,
      //     overlapping: false,
      //     semesterId: "HS 23 - Placeholder",
      // };
      // }),
      // "FS 24 - Placeholder": data[
      //   "df38c9b2-4db7-4389-8485-20fdb4318f40"
      // ].map((course) => {
      //   return {
      //     ...course,
      //     selected: false,
      //     allocated: false,
      //     overlapping: false,
      //     semesterId: "FS 24 - Placeholder",
      //   };
      // }),
      // "HS 24 - Placeholder": data[
      //   "65711e4a-eeea-4a9d-a9d9-d0b5476b746e"
      // ].map((course) => {
      //   return {
      //     ...course,
      //     selected: false,
      //     allocated: false,
      //     overlapping: false,
      //     semesterId: "HS 24 - Placeholder",
      //   };
      // }),
      // "FS 25 - Placeholder": data[
      //   "2dce7d87-6653-4c17-8b74-96371edbf894"
      // ].map((course) => {
      //   return {
      //     ...course,
      //     selected: false,
      //     allocated: false,
      //     overlapping: false,
      //     semesterId: "FS 25 - Placeholder",
      //   };
      // }),
      "HS 25 - Placeholder": data[
        "65711e4a-eeea-4a9d-a9d9-d0b5476b746e"
      ].map((course) => {
        return {
          ...course,
          selected: false,
          allocated: false,
          overlapping: false,
          semesterId: "HS 25 - Placeholder",
        };
      }),
      "FS 26 - Placeholder": data[
        "20102ba5-caff-4f47-94b4-350ea990e4f6"
      ].map((course) => {
        return {
          ...course,
          selected: false,
          allocated: false,
          overlapping: false,
          semesterId: "FS 26 - Placeholder",
        };
      }),
      "HS 26 - Placeholder": data[
        "65711e4a-eeea-4a9d-a9d9-d0b5476b746e"
      ].map((course) => {
        return {
          ...course,
          selected: false,
          allocated: false,
          overlapping: false,
          semesterId: "HS 26 - Placeholder",
        };
      }),
       "FS 27 - Placeholder": data[
        "20102ba5-caff-4f47-94b4-350ea990e4f6"
      ].map((course) => {
        return {
          ...course,
          selected: false,
          allocated: false,
          overlapping: false,
          semesterId: "FS 27 - Placeholder",
        };
      }),
      "HS 27 - Placeholder": data[
        "65711e4a-eeea-4a9d-a9d9-d0b5476b746e"
      ].map((course) => {
        return {
          ...course,
          selected: false,
          allocated: false,
          overlapping: false,
          semesterId: "HS 27 - Placeholder",
        };
      }),
      "FS 28 - Placeholder": data[
        "20102ba5-caff-4f47-94b4-350ea990e4f6"
      ].map((course) => {
        return {
          ...course,
          selected: false,
          allocated: false,
          overlapping: false,
          semesterId: "FS 28 - Placeholder",
        };
      }),
    };
    return newData;
  },
});

export function OverlappingEventDates(selectedEventDates) {
  const overlapping = selectedEventDates.filter((eventDate) => {
    const overlappingEventDates = selectedEventDates.filter(
      (eventDate2) => {
        //eventDate.overlapping = true;
        return (
          eventDate !== eventDate2 &&
          eventDate.courseNumber !== eventDate2.courseNumber &&
          eventDate.start <= eventDate2.end &&
          eventDate.end >= eventDate2.start
        );
      }
    );
    return overlappingEventDates.length > 0;
  });
  return overlapping;
}
