import { selector } from "recoil";

import { myStudyLevelDurationsState } from "../../atoms/hsg/myStudyLevelDurations";
import { cisTermAndPhaseInformationsState } from "../../atoms/hsg/cisTermAndPhaseInformations";

export const relevantCisIdsQuery = selector({
  key: "relevantCisIds",
  get: async ({ get }) => {
    const myStudyLevelDurations = await get(myStudyLevelDurationsState);

    // HAS TO BE DYNAMIC INSTEAD OF [0]
    if (!myStudyLevelDurations || myStudyLevelDurations.length === 0) {
      console.warn('myStudyLevelDurations is empty or undefined');
      return [];
    }

    const allMyStudyLevelDurations = myStudyLevelDurations.flatMap(program => 
      program.enrollments?.flatMap(enrollment => 
        enrollment.enrollmentTerms?.map(term => ({
          label: term.description,
          date: term.startDate,
        })) ?? []
      ) ?? []
    );

    const test = (myStudyLevelDurations || [])
  .flatMap((program) =>
    (program?.enrollments || []).flatMap((enrollment) =>
      (enrollment?.enrollmentTerms || []).map((term) => ({
        program: program?.shortName || 'Unknown Program',
        label: term?.description || 'Unknown Term',
        date: term?.startDate || null,
      }))
    )
  );

    const groupedByLabel = [];

    test.forEach((obj) => {
      const sem = {
        label: obj.label,
        date: obj.date,
      };
      // if groupedByLabel does not contain the label, add it
      if (!groupedByLabel.some((element) => element.label === obj.label)) {
        groupedByLabel.push(sem);
      }
    });

    const uniqueIds = [];

    const uniqueMyStudyLevelDurations = allMyStudyLevelDurations.filter(
      (element) => {
        const isDuplicate = uniqueIds.includes(element.label);
        if (!isDuplicate) {
          uniqueIds.push(element.label);
          return true;
        }
        return false;
      }
    );

    const cisTermAndPhaseInformations = await get(
      cisTermAndPhaseInformationsState
    );

    uniqueMyStudyLevelDurations.forEach((semester) => {
      const matchedTerm = cisTermAndPhaseInformations.find(
        (element) => element.description === semester.label
      );
      semester.termId = matchedTerm.timeSegmentId;
      semester.cisTermId = matchedTerm.id;
    });

    uniqueMyStudyLevelDurations.program = [];

    // add cistermandphaseinformation to the respective groupedByLabel[label]
    groupedByLabel.forEach((semester) => {
      const matchedProgram = cisTermAndPhaseInformations.find(
        (element) => element.description === semester.label
      );
      semester.termId = matchedProgram.timeSegmentId;
      semester.cisTermId = matchedProgram.id;
      semester.value = semester.label;
    });

    // return groupedByLabel;

    const temporaryGroupedByLabel = [
      // {
      //   "label": "HS 2022",
      //   "date": "2022-09-19T00:00:00",
      //   "termId": "67780ec0-88e3-4095-a998-2dbf77921493",
      //   "cisTermId": "d33babff-17c7-4997-b35b-a5348a2cb7bd",
      //   "value": "Herbstsemester 2022"
      // },
      // {
      //   "label": "FS 2023",
      //   "date": "2023-02-20T00:00:00",
      //   "termId": "180fed8a-9db7-4e6e-aebb-22f6959b0f42",
      //   "cisTermId": "df38c9b2-4db7-4389-8485-20fdb4318f40",
      //   "value": "Frühjahrssemester 2023"
      // },

      // {
      //   "label": "HS 2023",
      //   "date": "2023-08-12T00:00:00",
      //   "termId": "da0fc4f3-7942-4cac-85cd-d8a5f733fe97",
      //   "cisTermId": "74db9567-f466-44c3-a9d5-04cbecb0d084",
      //   "value": "Herbstsemester 2023"
      //  },

      // {
      //   label: "FS 2024",
      //   date: "2024-02-26T00:00:00",
      //   termId: "cdb7331b-2557-46b9-b5dd-4151d8bf0962",
      //   cisTermId: "2dce7d87-6653-4c17-8b74-96371edbf894",
      //   value: "Frühjahrssemester 2024",
      // },

      {
        label: "HS 2024",
        date: "2024-08-19T00:00:00",
        termId: "b6758836-d1d0-4731-a030-852322ebc880",
        cisTermId: "65711e4a-eeea-4a9d-a9d9-d0b5476b746e",
        value: "Herbstsemester 2024",
      },

      {
        label: "FS 2025",
        date: "2024-02-26T00:00:00",
        termId: "578663a7-e4fc-4b04-8ed9-0d43ef1e9d46",
        cisTermId: "20102ba5-caff-4f47-94b4-350ea990e4f6",
        value: "Frühjahrssemester 2025",
      },
    ];

    return temporaryGroupedByLabel;
  },
});
